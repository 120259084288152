<template>
  <div class="controls">
    <div
      class="layer--input-with-units"
      :class="{ 'layer--input-with-units--disabled': material.isDisabled }"
      v-if="material.valueType === 'input'"
    >
      <div class="value" v-if="material.isDisabled">
        {{ material.value }}
      </div>
      <input type="number" v-else min="0" max="1000" step="0.1" v-model.number="computedValue" />
      <span class="units">{{ $t('message.units.mm') }}</span>
    </div>
    <div
      class="layer-select"
      :class="{ 'layer-select--disabled': currentOptions.length === 1 }"
      v-if="material.valueType === 'select'"
    >
      <div class="value" v-if="currentOptions.length === 1">
        {{ currentOptions[0] }} {{ $t('message.units.mm') }}
      </div>
      <select v-model.number="computedValue" v-else>
        <option
          :value="option"
          :name="`child-layer-${layerIndex}-number-${itemIndex}`"
          v-for="(option, n) in currentOptions"
          :key="`option-${n}-layer-${layerIndex}`"
        >
          {{ option }} {{ $t('message.units.mm') }}
        </option>
      </select>
    </div>
    <div
        v-if="isMultilayerMode"
        class="layer--input-with-units layer--input-square"
        :content="$t('message.system.multilayer.squareHint')"
        v-tippy="{ placement: 'top', maxWidth: 200, duration: 1000 }"
    >
      <input type="number" min="0" max="1000" step="0.1" v-model="computedSquare" />
      <span class="units">{{ $t('message.units.m2') }}</span>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  props: {
    item: Object,
    layerIndex: Number,
    itemIndex: Number,
    multilayerIndex: {
      type: Number,
      default: () => null
    },
    isInCustom: Boolean,
    parentType: String
  },
  methods: {
    ...mapMutations({
      UPDATE_SECTOR_SYSTEM_LAYER_ITEM_VALUE: 'UPDATE_SECTOR_SYSTEM_LAYER_ITEM_VALUE',
      UPDATE_JUNCTION_LAYER_ITEM_VALUE: 'UPDATE_JUNCTION_LAYER_ITEM_VALUE',
      UPDATE_SECTOR_SYSTEM_LAYER_ITEM_MULTILAYER_VALUE: 'UPDATE_SECTOR_SYSTEM_LAYER_ITEM_MULTILAYER_VALUE',
      UPDATE_SECTOR_SYSTEM_LAYER_ITEM_MULTILAYER_SQUARE: 'UPDATE_SECTOR_SYSTEM_LAYER_ITEM_MULTILAYER_SQUARE'
    })
  },
  computed: {
    material() {
      return this.item.material ? this.item.material : this.item
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    isInSystem() {
      return this.parentType === 'system'
    },
    isMultilayerMode() {
      return this.parentType === 'multilayer'
    },
    currentOptions() {
      if (this.isInSystem) {
        return this.material.options
      } else {
        return this.isInCustom ? this.material.options : this.material.allowedOptions
      }
    },
    computedSquare: {
      get() {
        return this.material.square
      },
      set(value) {
        const square = parseFloat(value)
        if (!isNaN(square) && square >= 0) {
          this.UPDATE_SECTOR_SYSTEM_LAYER_ITEM_MULTILAYER_SQUARE({
            sectorIndex: this.sectorIndex,
            layerIndex: this.layerIndex,
            itemIndex: this.itemIndex,
            multilayerIndex: this.multilayerIndex,
            square
          })
        }
      }
    },
    computedValue: {
      get() {
        return this.material.value
      },
      set(value) {
        const newInputValue = parseFloat(value)
        if (!isNaN(newInputValue) && newInputValue >= 0) {
          if (this.isInSystem) {
            this.UPDATE_SECTOR_SYSTEM_LAYER_ITEM_VALUE({
              sectorIndex: this.sectorIndex,
              layerIndex: this.layerIndex,
              itemIndex: this.itemIndex,
              value: newInputValue
            })
          } else if (this.isMultilayerMode) {
            this.UPDATE_SECTOR_SYSTEM_LAYER_ITEM_MULTILAYER_VALUE({
              sectorIndex: this.sectorIndex,
              layerIndex: this.layerIndex,
              itemIndex: this.itemIndex,
              multilayerIndex: this.multilayerIndex,
              value: newInputValue
            })
          } else {
            this.UPDATE_JUNCTION_LAYER_ITEM_VALUE({
              sectorIndex: this.sectorIndex,
              layerIndex: this.layerIndex,
              itemIndex: this.itemIndex,
              junctionIndex: this.junctionIndex,
              value: newInputValue
            })
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="sass">
.layer
  &--input-square
    margin-left: rem(16)
    grid-template-columns: 73% 27%
.elements_multilayer.v-error
  .layer--input-square
    animation: blinking .5s linear infinite
</style>
