<template>
  <div class="single-system__layers">
    <div class="single-layer" v-for="(layer, i) in parentLayers" :key="`layer-${i}`">
      <div class="single-layer__header">
        <div class="single-layer__header-name">
          <div class="text">
            {{ layer.name }}
          </div>
        </div>
      </div>
      <div class="single-layer__body">
        <div
          class="single-layer__body-item"
          v-for="(item, n) in layer.items"
          :key="`child-layer-${i}-number-${n}-computed-${getCurrentNumber(i, n)}`"
        >
          <div class="elements" v-if="item.type === 'layer'">
            <div
              class="elements__left"
              @click="item.hasAlternate ? choseNewMaterial(layer.name, item.name, i, n) : null"
              :class="{
                'elements__left--full': !isShowInputOrSelect(item.material),
                'elements__left--disabled': !item.hasAlternate
              }"
            >
              <div class="elements__left-info">
                <div class="name">{{ item.name }}</div>
                <div class="material">{{ item.material.name }}</div>
              </div>
              <div class="chevron">
                <img
                  class=""
                  src="@/assets/img/icons/filter--select.svg"
                  alt="↓"
                  v-if="item.hasAlternate"
                />
                <img
                  class=""
                  src="@/assets/img/icons/filter--select--disabled.svg"
                  alt="↓"
                  v-else
                />
              </div>
            </div>
            <Input-or-select
              :item="item"
              v-if="isShowInputOrSelect(item.material)"
              parent-type="junction"
              :layerIndex="i"
              :isInCustom="false"
              :itemIndex="n"
            />
          </div>
          <Mount-layer
            v-else-if="item.type === 'mount'"
            :is-in-custom="false"
            :layer-index="i"
            :item-index="n"
            :item="item"
            :availableFasteners="item.options"
            :selected="item.selected"
            :height="item.height"
            :basement="item.basementType"
            :rate="item.mountRate"
            :is-first="getCurrentNumber(i, n) === 1"
          />
          <Single-layer-footer
            v-if="item.type === 'layer'"
            :layer-type="item.type"
            :type="currentJunction.type"
            :rate="item.materialRate"
            :tooltip="item.tooltip"
            :isShow="item.type === 'layer' ? item.material.isCalc : false"
            :units="item.type === 'layer' ? item.material.units : null"
            :mark="item.mark"
            :is-in-custom="false"
            :is-first="getCurrentNumber(i, n) === 1"
          />
        </div>
      </div>
    </div>
    <Modal
      :isModalOpen="isModalOpen"
      :isCloseInHeader="true"
      :isNoRadius="true"
      :isShowClose="true"
      @close="close"
    >
      <template #body>
        <LayerProductsView
          :path="layersPath"
          :layerIndex="layerIndexToSelect"
          :itemIndex="itemIndexToSelect"
          :placement="'junction'"
          :response="responseItems"
          @close="close"
        />
      </template>
    </Modal>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { getItemNumber, isShowElement } from '@/utils/customFunctions'
import Modal from '@/components/elements/Modals/Modal'
import LayerProductsView from '@/components/smart/layersProducts/ProductSelection'
import InputOrSelect from '@/components/elements/Dom/InputOrSelect'
import MountLayer from '@/components/smart/Junction/MountLayer'
import SingleLayerFooter from '@/components/smart/Junction/SingleLayerFooter'
import { getMaterialsForLayer } from '@/api'

export default {
  data: () => ({
    isModalOpen: false,
    layersPath: [],
    layerIndexToSelect: '',
    itemIndexToSelect: '',
    isLoading: false,
    responseItems: {}
  }),
  components: {
    Modal,
    LayerProductsView,
    InputOrSelect,
    MountLayer,
    SingleLayerFooter
  },
  methods: {
    ...mapMutations({
      UPDATE_JUNCTION_LAYER_ITEM_VALUE: 'UPDATE_JUNCTION_LAYER_ITEM_VALUE'
    }),
    close() {
      this.isModalOpen = false
    },
    choseNewMaterial(layer, item, layerIndex, itemIndex) {
      this.isLoading = true
      getMaterialsForLayer(
        this.$i18n.locale, this.isCustomJunction ? 'junction_alter' : 'junction',
        layerIndex,
        itemIndex,
        'stock',
        this.currentJunction.id
      ).then(response => {
        this.isLoading = false
        this.isModalOpen = !this.isModalOpen
        this.layerIndexToSelect = layerIndex
        this.itemIndexToSelect = itemIndex
        this.responseItems = response.data
        this.layersPath = [layer, item]
      })
    },
    getCurrentNumber(parentIteration, childIteration) {
      return getItemNumber(parentIteration, childIteration, this.parentLayers)
    },
    isShowInputOrSelect(material) {
      return isShowElement(material)
    }
  },
  computed: {
    ...mapState({
      allFasteners: state => state.fasteners,
      layersProducts: state => state.layersProducts
    }),
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    },
    currentJunctions() {
      return this.sectorFromState.junctions
    },
    currentJunction() {
      return this.currentJunctions[this.junctionIndex]
    },
    parentLayers() {
      return this.currentJunction.layers
    },
    isCustomJunction() {
      return this.currentJunction?.variables !== null
    }
  }
}
</script>
<style lang="sass" scoped>
// @import "~@/assets/sass/_layers.sass"
</style>
<style lang="sass">
.single-junction
  .single-layer__body-item
    .btn-handle
      grid-row: 1 / 2
</style>
