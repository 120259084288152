<template>
  <div class="single-system__layers-add" :class="{ active: isShowList }">
    <button @click="isShowList = !isShowList" class="btn">
      <span class="icon">
        <img src="@/assets/img/icons/layer--add-close--big.svg" alt="❌" v-if="isShowList" />
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          v-else
        >
          <circle cx="12" cy="12" r="12" fill="#94E3B5"></circle>
          <path
            d="M12 18V6M18 12H6"
            stroke="white"
            stroke-width="2"
            stroke-miterlimit="22.9256"
            stroke-linejoin="round"
          ></path>
        </svg>
      </span>
      <span class="text">
        {{ $t('message.add.layer') }}
      </span>
    </button>
    <div class="list" v-if="isShowList">
      <materials-search
        :layer-id="layerId"
        :layer-index="layerIndex"
        :type="placement"
        @setParentLayerId="setParentLayerId"
        is-parent-layer-search
        @hideList="hideList"
      />
      <div class="list__item" v-for="layer in needNowList" :key="layer.id">
        <input
          type="radio"
          :value="layer.id"
          :id="`parent-layer-selection-${layer.id}`"
          class="input"
          @change="selectLayer"
        />
        <label :for="`parent-layer-selection-${layer.id}`" class="label">{{ layer.name }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import MaterialsSearch from '@/components/smart/MaterialsSearch'
export default {
  components: { MaterialsSearch },
  props: {
    placement: String
  },
  data: () => ({
    isShowList: false,
    layerId: null,
    layerIndex: 0
  }),
  methods: {
    ...mapMutations({
      ADD_PARENT_LAYER_TO_SYSTEM: 'ADD_PARENT_LAYER_TO_SYSTEM',
      ADD_PARENT_LAYER_TO_JUNCTION: 'ADD_PARENT_LAYER_TO_JUNCTION'
    }),
    selectLayer(e) {
      if (this.placement === 'system') {
        this.ADD_PARENT_LAYER_TO_SYSTEM({
          sectorIndex: this.sectorIndex,
          layerId: parseInt(e.target.value)
        })
      } else {
        this.ADD_PARENT_LAYER_TO_JUNCTION({
          sectorIndex: this.sectorIndex,
          junctionIndex: this.junctionIndex,
          layerId: parseInt(e.target.value)
        })
      }
      this.isShowList = false
    },
    setParentLayerId(layerId) {
      this.layerId = layerId
    },
    hideList() {
      this.isShowList = false
    }
  },
  computed: {
    ...mapState({
      systemLayersList: state => state.layersList,
      junctionsLayersList: state => state.junctionsLayersList
    }),
    filteredLayersList() {
      return this.sectorFromState.layers.length === 0
        ? this.systemLayersList.filter(p => Object.prototype.hasOwnProperty.call(p, 'isBaseLayer'))
        : this.systemLayersList.filter(p => !Object.prototype.hasOwnProperty.call(p, 'isBaseLayer'))
    },
    needNowList() {
      return this.placement === 'system' ? this.filteredLayersList : this.junctionsLayersList
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    isInSystem() {
      return this.placement === 'system'
    }
  }
}
</script>

<style lang="sass" scoped>
.single-system__layers
  &-add
    &.active
      background: #fff
      box-shadow: $default-box-shadow
      border-radius: rem(4) rem(4) 0 0
      .btn
        border-color: transparent
        box-shadow: inset 0 rem(-1) 0 #ECECEC
        background: #fff
        &:hover
          box-shadow: inset 0 rem(-1) 0 #ECECEC
          background: #fff
    .btn
      @extend .clear-btn
      @extend .fs-14
      display: flex
      flex-direction: row
      align-items: center
      font-weight: bold
      background: rgba(255, 255, 255, 0.5)
      width: 100%
      height: rem(56)
      text-align: left
      padding: 0 rem(20)
      border: rem(1) dashed #D6D6D6
      border-radius: rem(4)
      @media (any-hover: hover)
        &:hover
          background: #ffffff
          border-color: transparent
          box-shadow: $default-box-shadow
          .icon
            circle
              fill: $green
      .icon
        line-height: 0
        circle
          transition: .5s
      .text
        padding-left: rem(20)
    .list
      +media((padding: (0: rem(16) rem(20), 576: rem(16) rem(40))))
      width: 100%
      box-sizing: border-box
      // background: #fff
      &__item
        &:not(:last-child)
          box-shadow: inset 0 rem(-1) 0 #ECECEC
        .input
          display: none
          pointer-events: none
          position: absolute
          opacity: 0
          &:checked
            + label
              background-color: $red
              background-image: url("~@/assets/img/icons/filter--check.svg")
              font-weight: 700
              padding-left: rem(40)
              color: #fff
        .label
          box-sizing: border-box
          cursor: pointer
          transition: .5s
          width: 100%
          display: flex
          flex-direction: row
          align-items: center
          padding: 0 rem(20)
          border-radius: rem(4)
          height: rem(48)
          background-repeat: no-repeat
          background-position: rem(16) center
          @extend .fs-14
          &:hover
            background-color: $red
            padding-left: rem(40)
            background-image: url("~@/assets/img/icons/filter--check.svg")
            font-weight: 700
            color: #fff
</style>
