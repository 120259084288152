<template>
  <div class="control-panel">
    <div class="control-panel__footer">
      <div class="control-panel__footer-item">
        <SimpleButton color="green" font="minor" @clickedFromSimpleBtn="addAnotherJunction">
          <template #icon>
            <img src="@/assets/img/icons/new-adjoining-node.svg" alt="" class="icon" />
          </template>
          <i18n path="message.buttons.addJunction">
            <template #break>
              <br />
            </template>
          </i18n>
        </SimpleButton>
        <div class="text">
          {{ $t('message.junction.sidebar.junctionAdd') }}
        </div>
      </div>
      <div class="control-panel__footer-item">
        <SimpleButton color="green" font="minor" @clickedFromSimpleBtn="addNewSector">
          <template #icon>
            <img src="@/assets/img/icons/new-sector.svg" alt="" class="icon" />
          </template>
          <i18n path="message.buttons.newSector">
            <template #break>
              <br />
            </template>
          </i18n>
        </SimpleButton>
        <div class="text">
          {{ $t('message.junction.sidebar.sectorAdd') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import SimpleButton from '@/components/elements/Dom/Simple-button'
export default {
  inject: ['slideToLast'],
  props: {
    isCurrentJunctionCountValid: {
      type: Boolean,
      required: true
    }
  },
  components: {
    SimpleButton
  },
  methods: {
    ...mapMutations({
      ADD_NEW_SECTOR: 'ADD_NEW_SECTOR'
    }),
    addAnotherJunction() {
      if (this.isCurrentJunctionCountValid) {
        this.$router.push(`/${this.$i18n.locale}/junctions/${this.sectorIndex}`)
        // this.$router.push({path: `/${this.$i18n.locale}/junctions/${this.sectorIndex}`});
        // this.$router.push({name: 'Junctions', params: {lang: `${this.$i18n.locale}`, id: `${this.sectorIndex}`, isAddNew: true}});
        this.$root.onBodyScroll()
      } else {
        this.$emit('showError')
      }
    },
    addNewSector() {
      if (this.isCurrentJunctionCountValid) {
        this.ADD_NEW_SECTOR()
        this.$router.push(`/${this.$i18n.locale}/systems/${this.sectors.length - 1}`)
        setTimeout(() => {
          this.slideToLast()
        }, 0)
        this.$root.onBodyScroll()
      } else {
        this.$emit('showError')
      }
    }
  },
  computed: {
    ...mapState({
      sectors: state => state.sectors
    }),
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    currentJunctions() {
      return this.sectorFromState.junctions
    },
    currentJunction() {
      return this.currentJunctions[this.junctionIndex]
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    }
  }
}
</script>

<style scoped lang="sass">
.control-panel
  background: #fff
  padding: rem(16) rem(40) rem(6)
  &__item
    display: flex
    flex-direction: row
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    &-title
      @extend .fs-12
      color: $light-black
    &-select
      margin: rem(6) 0 rem(8)
      width: 100%
      position: relative
      &:after
        content: ""
        position: absolute
        top: 50%
        transform: translateY(-50%)
        right: rem(15)
        height: rem(10)
        width: rem(10)
        background-image: url(~@/assets/img/select.svg)
        background-position: center
        background-repeat: no-repeat
        pointer-events: none
        background-size: contain
      .select
        background: #FFFFFF
        border: rem(1) solid #D6D6D6
        width: 100%
        box-sizing: border-box
        border-radius: rem(4)
        height: rem(40)
        outline: none
        box-shadow: none
        font-family: 'Montserrat', sans-serif
        font-style: normal
        font-weight: bold
        line-height: rem(15)
        color: $black
        font-size: rem(14)
        padding-left: rem(14)
        -webkit-appearance: none
        -moz-appearance: none
        cursor: pointer
    &-input
      @extend .input-with-units
      .input
        text-align: left
      .units
        font-weight: bold
        font-size: rem(12)
        line-height: 160%
        color: $dark-grey
  &__footer
    padding-top: rem(16)
    .simple-btn
      width: 100%
      justify-content: flex-start
    .text
      padding: rem(9) 0 rem(18)
      @extend .fs-12
      color: $dark-grey
</style>
