<template>
  <div class="layer-selection__items">
    <div class="title">
      <slot></slot>
    </div>
    <div class="item" v-for="item in currentLayers" :key="item.id" @click="selectLayer(item)">
      {{ item.name }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    foundedLayers: {
      type: Array,
      default: () => []
    },
    foundedParentLayers: {
      type: Array,
      default: () => []
    },
    layerId: {
      type: Number,
      default: null
    },
    type: String
  },
  name: 'LayerSelectionItems',
  methods: {
    selectLayer(layer) {
      this.$emit('addLayer', layer)
    }
  },
  computed: {
    ...mapState({
      systemLayersList: state => state.layersList,
      junctionsLayersList: state => state.junctionsLayersList
    }),
    layersList() {
      return this.isInSystem ? this.systemLayersList : this.junctionsLayersList
    },
    currentChildLayers() {
      return this.layersList
        .find(layer => layer.id === this.layerId)
        .items.filter(item => this.foundedLayers.includes(item.id))
    },
    currentParentLayers() {
      return this.layersList.filter(layer => this.foundedParentLayers.includes(layer.id))
    },
    currentLayers() {
      return this.layerId !== null ? this.currentChildLayers : this.currentParentLayers
    },
    isInSystem() {
      return this.type === 'system'
    }
  }
}
</script>

<style scoped lang="sass">
.layer-selection__items
  // box-shadow: inset 0 rem(-1) 0 $red
  // padding-bottom: rem(16)
  .title
    @extend .fs-14
    font-weight: bold
    +media((margin-bottom: (0: rem(10), 768: rem(20))))
    padding: 0 rem(20)
  .item
    box-sizing: border-box
    cursor: pointer
    transition: .5s
    width: 100%
    display: flex
    flex-direction: row
    align-items: center
    padding: 0 rem(20)
    border-radius: rem(4)
    height: rem(48)
    background-repeat: no-repeat
    background-position: calc(100% - 18px) center
    @extend .fs-14
    // &:not(:last-child)
      box-shadow: inset 0 rem(-1) 0 $cool_B

    @media(any-hover: hover)
      &:hover
        // box-shadow: none
        background-color: $red
        background-image: url("~@/assets/img/icons/filter--check.svg")
        font-weight: 700
        color: $white
</style>
